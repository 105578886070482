var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"title":'编辑活动类型',"visible":_vm.integralRuleVisible,"width":"fit-content","close-on-click-modal":false},on:{"update:visible":function($event){_vm.integralRuleVisible=$event},"closed":_vm.close}},[(_vm.integralRuleVisible)?_c('el-form',{ref:"integralRuleForm",attrs:{"label-width":"80px","model":_vm.integralRuleData}},[_c('el-table',{attrs:{"data":_vm.integralRuleData.items,"border":"","max-height":"350"}},[_c('el-table-column',{attrs:{"label":"活动类型","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label":"","prop":'items.' + scope.$index + '.name',"label-width":"0","rules":[
                { required: scope.$index < _vm.integralRuleData.items.length - 1, message: '请输入活动类型', trigger: ['blur', 'change'] },
                { min: scope.$index < _vm.integralRuleData.items.length - 1 ? 1 : 0, max: 12, message: '长度在 ' + (scope.$index < _vm.integralRuleData.items.length - 1 ? 1 : 0) + ' 到 10 个字符', trigger: ['blur', 'change'] }
            ]}},[_c('el-input',{attrs:{"placeholder":"活动类型","maxlength":"12"},model:{value:(scope.row.name),callback:function ($$v) {_vm.$set(scope.row, "name", $$v)},expression:"scope.row.name"}})],1)]}}],null,false,3288141705)}),_c('el-table-column',{attrs:{"label":"合并","width":"60","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label":"","label-width":"0"}},[_c('el-checkbox',{model:{value:(scope.row.merge),callback:function ($$v) {_vm.$set(scope.row, "merge", $$v)},expression:"scope.row.merge"}})],1)]}}],null,false,381492629)}),_c('el-table-column',{attrs:{"label":"积分上限","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label":"","label-width":"0","prop":'items.' + scope.$index + '.max',"rules":[
                { required: false, message: '请输入排序', trigger: ['blur', 'change'] },
                { validator: _vm.validatorInteger, trigger: ['change', 'blur'] }
            ]}},[_c('el-input',{attrs:{"placeholder":"不限制","type":"number"},model:{value:(scope.row.max),callback:function ($$v) {_vm.$set(scope.row, "max", $$v)},expression:"scope.row.max"}})],1)]}}],null,false,2417036332)}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"margin-bottom":"18px"}},[(scope.$index < _vm.integralRuleData.items.length - 1)?_c('popconfirmButton',{staticStyle:{"margin-left":"10px"},attrs:{"content":"是否确认删除？","text":"删除"},on:{"onConfirm":function($event){return _vm.integralRuleData.items.splice(scope.$index, 1)}}}):_c('el-button',{staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.integralRuleData.items.push({ _id: '', name: '', max: '', year_max: '', merge: false })}}},[_vm._v("添加")])],1)]}}],null,false,2244566511)})],1),_c('div',{staticStyle:{"color":"#F56C6C","font-size":"12px","text-align":"center","margin":"5px"}},[_vm._v("*填写内容需要点击“添加”按钮，否则不会保存")]),_c('div',{staticStyle:{"width":"100%","text-align":"center","margin-top":"10px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.saveIntegralRule}},[_vm._v("确定")]),_c('el-button',{staticStyle:{"margin-left":"20px"},on:{"click":function($event){_vm.integralRuleVisible=false;}}},[_vm._v("取消")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }