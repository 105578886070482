<template>
  <perContainer :perm="`armory:${type}:import${sub_type}`">
    <el-form-item>
      <!--    <el-popover-->
      <!--        placement="bottom-end"-->
      <!--        title="导入历史"-->
      <!--        width="400"-->
      <!--        trigger="manual"-->
      <!--        v-model="visibleImportHistory"-->
      <!--        @click.stop="aaa">-->
      <!--      <div style="max-height: 600px; overflow-y: auto">-->

      <!--      </div>-->
      <!--      <div slot="reference" style="width: fit-content; height: fit-content">-->
      <!--        <perButton v-loading="loadingHistory" :perm="`armory:${type}:importinfo`" size="small" type="warning" @click="showImportHistory">导入历史</perButton>-->
      <!--      </div>-->
      <!--    </el-popover>-->

        <el-button v-loading="loadingHistory" :perm="`armory:${type}:import${sub_type}`" size="small" type="warning" @click="showImportHistory">导入历史</el-button>
        <el-link class="down_load_link" :href="`../../import_template/${type}_${sub_type}.xlsx`" target="_blank" style="margin-left: 10px;">下载模板</el-link>
    </el-form-item>
    <el-drawer
        title="我是标题"
        :visible.sync="visibleImportHistory"
        :with-header="false">
      <div style="line-height: 24px;padding: 10px">
        <div v-for="(item, index) in importHistory" :key="'import_' + index" style="border-radius: 10px; border: 1px solid #ebeef5; padding: 10px; box-sizing: border-box; margin-top: 5px; margin-bottom: 5px">
          <div style="display: flex; flex-direction: column">
            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
              <div style="font-size: 13px">{{ `用户名：${item.user_name|| 'admin'}` }}</div>
              <div v-if="item.sheet_name && item.sheet_name.length > 0" >
                <div style="font-size: 11px">
                  {{`工作表名称：${item.sheet_name}`}}
                </div>
                <!--                <div>-->
                <!--                  {{`失败数据：第${item.result.failedList.map(r => r.index + 1).join('，')}条`}}-->
                <!--                </div>-->
              </div>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
              <div style="font-size: 13px">
                {{ `成功：${item.result.success}条, 失败：${item.result.failed}条` }}
              </div>
              <div style="font-size: 11px; display: flex; justify-content: center; align-items: center">{{ new Date(item.start).format('yyyy/MM/dd hh:mm:ss') }}</div>
            </div>
          </div>
          <div v-if="item.msg && item.msg.length > 0" style="line-height: 16px; padding: 5px 10px; margin-top: 5px; border-top: #ebeef5 1px dashed">
            <div style="color: #9F4648; font-size: 11px">
              {{item.msg}}
            </div>
          </div>

          <div v-if="item.result.failed > 0 && item.result.failedList && item.result.failedList.length > 0" style="line-height: 16px; padding: 5px 10px; border-top: #ebeef5 1px dashed; margin-top: 5px">
            <div v-for="(failed, i) in item.result.failedList" :key="`failed_${i}`" style="color: #9F4648; font-size: 11px">
              <span v-if="item.showAll || i < 2">
                {{`第${Array.isArray(failed.index) ? failed.index.map(r => r + 1).join(',') : ((failed.index + 1) + "")}条数据失败，原因：${failed.msg}`}}
              </span>
            </div>
            <div v-if="item.result.failedList.length > 2">
              <el-button type="text" @click="changeShowAll(item)">{{ item.showAll ? "合并" : "展开" }}</el-button>
            </div>
            <!--                <div>-->
            <!--                  {{`失败数据：第${item.result.failedList.map(r => r.index + 1).join('，')}条`}}-->
            <!--                </div>-->
          </div>
        </div>
        <div v-if="!importHistory || !importHistory.length" style="text-align: center; color: #8c939d; font-size: 13px">
          无导入记录
        </div>
      </div>
    </el-drawer>
  </perContainer>
</template>

<script>
export default {
  name: "importHistoryButton",
  components: {
  },
  props: {
    type: {
      type: String
    },
    sub_type: {
      type: String,
      default: 'info'
    }
  },
  data() {
    return {
      importHistory: [],
      loadingHistory: false,
      visibleImportHistory: false,
    };
  },
  methods: {
    aaa() {},
    changeShowAll(item) {
      item.showAll = !item.showAll;
      this.$forceUpdate();
    },
    async showImportHistory() {
      if (this.visibleImportHistory) {
        this.visibleImportHistory = false;
        return;
      }

      this.loadingHistory = true;
      let { data, errorCode } = await this.$http.armory.importHistory(this.type, this.sub_type);
      this.loadingHistory = false;
      if (errorCode != '0000') {
        return;
      }

      this.importHistory = data;
      this.visibleImportHistory = true;
    },
  },
  created() {
    // document.body.addEventListener("click", () => {
    //   this.visibleImportHistory = false;
    // }, this);
  }
}
</script>

<style >
  .el-popover__title {
    border-bottom: 1px solid #ebeef5!important;
    padding-bottom: 10px;
    color: #444444;
    font-weight: bold;
  }
</style>
