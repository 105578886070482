<template v-if="false">
  <el-dialog
      :title="'编辑活动类型'"
      :visible.sync="integralRuleVisible"
      width="fit-content"
      v-loading="loading"
      @closed="close"
      :close-on-click-modal="false"
  >
    <el-form ref="integralRuleForm"
             label-width="80px"
             v-if="integralRuleVisible"
             :model="integralRuleData">
      <el-table
          :data="integralRuleData.items"
          border
          max-height="350"
      >
        <el-table-column label="活动类型" width="200">
          <template slot-scope="scope">
            <el-form-item label="" :prop="'items.' + scope.$index + '.name'" label-width="0" :rules="[
                  { required: scope.$index < integralRuleData.items.length - 1, message: '请输入活动类型', trigger: ['blur', 'change'] },
                  { min: scope.$index < integralRuleData.items.length - 1 ? 1 : 0, max: 12, message: '长度在 ' + (scope.$index < integralRuleData.items.length - 1 ? 1 : 0) + ' 到 10 个字符', trigger: ['blur', 'change'] }
              ]" >
              <el-input placeholder="活动类型" v-model="scope.row.name" maxlength="12"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="合并" width="60" align="center">
          <template slot-scope="scope">
            <el-form-item label="" label-width="0">
              <el-checkbox v-model="scope.row.merge"></el-checkbox>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="积分上限" width="200">
          <template slot-scope="scope">
            <el-form-item label="" label-width="0" :prop="'items.' + scope.$index + '.max'" :rules="[
                  { required: false, message: '请输入排序', trigger: ['blur', 'change'] },
                  { validator: validatorInteger, trigger: ['change', 'blur'] }
              ]" >
              <el-input placeholder="不限制" type="number" v-model="scope.row.max"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="scope">
            <div style="margin-bottom: 18px">
              <popconfirmButton v-if="scope.$index < integralRuleData.items.length - 1" content="是否确认删除？" text="删除" @onConfirm="integralRuleData.items.splice(scope.$index, 1)" style="margin-left: 10px"></popconfirmButton>
              <el-button v-else @click="integralRuleData.items.push({ _id: '', name: '', max: '', year_max: '', merge: false })" style="margin-left: 10px">添加</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="color: #F56C6C; font-size: 12px; text-align: center; margin: 5px">*填写内容需要点击“添加”按钮，否则不会保存</div>
      <div style="width: 100%; text-align: center; margin-top: 10px">
        <el-button type="primary" @click="saveIntegralRule">确定</el-button>
        <el-button @click="integralRuleVisible=false;" style="margin-left: 20px">取消</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: "integralRuleManager",
  emits: ["onClose"],
  props: {
    type: {
      type: String
    }
  },
  data() {
    return {
      integralRuleVisible: true,
      integralRuleData: {
        items: []
      },
    }
  },
  methods: {
    validatorInteger(rule, value, callback) {
       if (!value || !value.length || !value.trim()?.length) {
    callback();
    return;
  }

      if(isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) > 0) {
        callback();
      } else {
        callback(new Error("请输入大于0的整数"));
      }
    },
    close() {
      this.$emit("onClose");
    },
    async handleShowIntegralRule() {
      this.loading = true;
      let {data, errorCode} = await this.$http.armory.getIntegralRule(this.type);
      this.loading = false;

      if(errorCode != '0000') {
        return;
      }

      this.integralRuleVisible = true;
      this.integralRuleData.items = [
        ...data.map(r => {
          return {
            _id: r._id,
            name: r.name,
            max: (r.max && r.max > 0 ? r.max : "") + "",
            year_max: (r.year_max && r.year_max > 0 ? r.year_max : "") + "",
            merge: r.merge || false
          }
        }),
        {
          _id: '',
          name: '',
          max: '',
          year_max: '',
          merge: false
        }
      ];

      // this.$refs.integralRuleForm.resetFields();
    },
    isRepeat(arr){
      var hash = {};
      for(var i in arr) {
        if(hash[arr[i]])
          return true;
        hash[arr[i]] = true;
      }
      return false;
    },
    async saveIntegralRule() {
      console.log(111);
      this.$refs.integralRuleForm.validate(async (valid, feilds) => {
        console.log(feilds)
        if (valid) {
          let saveData = this.integralRuleData.items.length > 1 ? this.integralRuleData.items.slice(0, this.integralRuleData.items.length - 1) : [];
          saveData = saveData.map(r => {
            return {
              ...r,
              name: r.name.trim(),
              max: r.max && r.max.trim().length ? Number.parseInt(r.max) : -1,
              year_max: r.year_max && r.year_max.trim().length ? Number.parseInt(r.year_max) : -1
            }
          });
          if(this.isRepeat(saveData.map(r => r.name))) {
            this.$message({
              type: "error",
              message: "存在相同的名称,不能保存",
            });
            return;
          }

          this.loading = true;
          let {data, errorCode} = await this.$http.armory.saveIntegralRule(this.type, {
            items: saveData
          });

          this.loading = false;

          if(errorCode != '0000') {
            return;
          }

          this.$message({
            type: "success",
            message: "保存成功",
          });

          this.close();
        }
      });
    },
  },
  created() {
    this.handleShowIntegralRule();
  }
}
</script>

<style scoped>

</style>
