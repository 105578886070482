<template>
  <el-dialog
    :title="'编辑活动积分'"
    :visible.sync="integralVisible"
    @closed="close"
    v-loading="loading"
    :close-on-click-modal="false"
  >
    <el-form ref="integralForm" label-width="100px" :model="dataForm">
      <el-form-item label="门派名称" required>
        <div>{{ sect_name }}</div>
      </el-form-item>
      <!-- 期榜 -->
      <el-form-item
        label="期榜"
        required
        prop="select_season"
        :rules="[
          {
            required: true,
            message: '请选择期榜',
            trigger: ['blur', 'change'],
          },
        ]"
      >
        <div v-if="from == 'rank'">{{ season_name }}</div>
        <el-select v-else ref="select_season" v-model="dataForm.select_season">
          <el-option value="" label="请选择"></el-option>
          <el-option
            v-for="(item, index) in seasonList"
            :value="item._id"
            :label="item.name + '-' + item.year + '年'"
            :key="`season_${index}`"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="积分"
        required
        prop="items"
        :rules="[
          {
            type: 'array',
            required: true,
            min: 2,
            message: '请添加积分',
            trigger: 'change',
          },
        ]"
      >
        <div>{{ total_value }}</div>
        <el-table :data="dataForm.items" border max-height="350">
          <el-table-column label="活动类型">
            <template slot-scope="scope">
              <el-form-item
                label=""
                :prop="'items.' + scope.$index + '.type_name'"
                label-width="0"
                :rules="[
                  {
                    required: scope.$index < dataForm.items.length - 1,
                    message: '请输入活动类型',
                    trigger: ['blur', 'change'],
                  },
                  {
                    min: scope.$index < dataForm.items.length - 1 ? 1 : 0,
                    max: 12,
                    message:
                      '长度在 ' +
                      (scope.$index < dataForm.items.length - 1 ? 1 : 0) +
                      ' 到 12 个字符',
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-input
                  placeholder="活动类型"
                  v-model="scope.row.type_name"
                  maxlength="12"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="活动详情">
            <template slot-scope="scope">
              <el-form-item
                label=""
                :prop="'items.' + scope.$index + '.type_sub_name'"
                label-width="0"
                :rules="[
                  {
                    required: scope.$index < dataForm.items.length - 1,
                    message: '请输入活动详情',
                    trigger: ['blur', 'change'],
                  },
                  {
                    min: scope.$index < dataForm.items.length - 1 ? 1 : 0,
                    max: 12,
                    message:
                      '长度在 ' +
                      (scope.$index < dataForm.items.length - 1 ? 1 : 0) +
                      ' 到 12 个字符',
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-input
                  placeholder="活动详情"
                  v-model="scope.row.type_sub_name"
                  maxlength="12"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="积分">
            <template slot-scope="scope">
              <el-form-item
                label=""
                label-width="0"
                :prop="'items.' + scope.$index + '.value'"
                :rules="[
                  {
                    required: scope.$index < dataForm.items.length - 1,
                    message: '请输入积分',
                    trigger: ['blur', 'change'],
                  },
                  { validator: validatorInteger, trigger: ['change', 'blur'] },
                ]"
              >
                <el-input
                  placeholder="积分"
                  type="number"
                  v-model="scope.row.value"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="排序">
            <template slot-scope="scope">
              <el-form-item
                label=""
                label-width="0"
                :prop="'items.' + scope.$index + '.sort'"
                :rules="[
                  {
                    required: scope.$index < dataForm.items.length - 1,
                    message: '请输入排序',
                    trigger: ['blur', 'change'],
                  },
                  { validator: validatorInteger, trigger: ['change', 'blur'] },
                ]"
              >
                <el-input
                  placeholder="排序"
                  type="number"
                  v-model="scope.row.sort"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <div style="margin-bottom: 18px">
                <popconfirmButton
                  v-if="scope.$index < dataForm.items.length - 1"
                  content="是否确认删除？"
                  text="删除"
                  @onConfirm="dataForm.items.splice(scope.$index, 1)"
                  style="margin-left: 10px"
                ></popconfirmButton>
                <el-button
                  v-else
                  @click="
                    dataForm.items.push({
                      type_name: '',
                      type_sub_name: '',
                      ext: '',
                      value: '',
                    })
                  "
                  style="margin-left: 10px"
                  >添加</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div
          style="color: #F56C6C; font-size: 12px; text-align: center; margin: 5px"
        >
          *填写内容需要点击“添加”按钮，否则不会保存
        </div>
      </el-form-item>

      <div style="width: 100%; text-align: center; margin-top: 10px">
        <el-button type="primary" @click="saveIntegral">确定</el-button>
        <el-button @click="close" style="margin-left: 20px">取消</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { MessageBox } from 'element-ui'

export default {
  name: 'integralEdit',
  emits: ['onClose'],
  props: {
    sect_name: {
      type: String,
    },
    ref_id: {
      type: String,
    },
    season_id: {
      type: String,
    },
    season_name: {
      type: String,
    },
    from: {
      type: String,
      default: 'rank',
    },
  },
  computed: {
    total_value() {
      let v = 0
      for (let i = 0; i < this.dataForm.items.length - 1; i++) {
        let item = Number.parseInt(this.dataForm.items[i].value)
        v += isNaN(item) ? 0 : item
      }

      return v
    },
  },
  data() {
    return {
      type: 'sect',
      loading: true,
      seasonList: [],
      integralRules: [],
      integralVisible: true,
      dataForm: {
        select_season: '',
        items: [],
      },
    }
  },
  watch: {
    'dataForm.select_season': {
      handler: function(val) {
        if (val && val.length) {
          this.bindSeasonData()
        }
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.$emit('onClose')
    },
    typeChange(row) {
      let type = this.integralRules.find((r) => r._id == row.type_id)
      if (type) {
        row.sort = type.sort
      }
    },
    isRepeat(arr) {
      var hash = {}
      for (var i in arr) {
        if (hash[arr[i]]) return true
        hash[arr[i]] = true
      }
      return false
    },
    async saveIntegral() {
      const valid = await this.$refs.integralForm.validate()
      if (valid) {
        let saveData =
          this.dataForm.items.length > 1
            ? this.dataForm.items.slice(0, this.dataForm.items.length - 1)
            : []

        saveData.forEach((r) => {
          r.type_name = r.type_name.trim()
          r.type_sub_name = r.type_sub_name.trim()
        })

        if (
          this.isRepeat(
            saveData.map((r) => r.type_name + '_' + r.type_sub_name)
          )
        ) {
          this.$message({
            type: 'error',
            message: '存在相同的类型,不能保存',
          })
          return
        }

        const confirm = await this.$confirm(
          '如果积分信息是“线上”状态，将会恢复为“编辑中”，是否继续保存?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )

        if (confirm !== 'confirm') {
          return
        }

        this.loading = true
        saveData = {
          season_id: this.dataForm.select_season,
          ref_id: this.ref_id,
          items: saveData.map((r) => {
            return {
              type_name: r.type_name,
              type_sub_name: r.type_sub_name,
              value: Number.parseInt(r.value),
              ext: r.ext,
            }
          }),
        }
        console.log('🚀 ~ saveIntegral ~ saveData:', saveData, this.type)
        const { data, errorCode } = await this.$http.armory.saveRank(
          this.type,
          saveData
        )

        this.loading = false

        if (errorCode !== '0000') {
          return
        }

        this.$message({
          type: 'success',
          message: '保存成功',
        })

        this.close()
        this.$bus.$emit('onSectRankChanged', data)
      }
      console.log('🚀 ~ saveIntegral ~ this.type:', this.type)
      console.log('🚀 ~ saveIntegral ~ this.type:', this.type)
    },
    validatorInteger(rule, value, callback) {
      if (!value) {
        callback()
        return
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error('请输入大于0的整数'))
      }

      if (Number.isInteger(Number(value)) && Number(value) > 0) {
        callback()
      } else {
        callback(new Error('请输入大于0的整数'))
      }
    },
    //
    hasIntegralRule(id) {
      return this.integralRules.findIndex((r) => r._id == id) >= 0
    },
    async reloadSeasons() {
      const { data, errorCode } = await this.$http.armory.seasonList('sect', {
        valid: '1',
        pageNum: 0,
        pageSize: 1000,
      })
      if (errorCode != '0000') return
      this.seasonList = data.data
      if (
        this.from != 'rank' &&
        !this.seasonList.find((r) => r._id == this.dataForm.select_season)
      ) {
        this.dataForm.select_season = ''
      }
    },
    // 期榜数据
    async bindSeasonData() {
      if (!this.dataForm.select_season || !this.dataForm.select_season.length) {
        return
      }
      this.loading = true
      let { data, errorCode } = await this.$http.armory.detailRank(
        this.dataForm.select_season,
        this.ref_id,
        'sect'
      )
      if (errorCode != '0000') {
        return
      }

      this.dataForm.items = []
      if (data && data.items) {
        data.items.forEach((r) => {
          if (r.merge && r.items && r.items.length) {
            r.items.forEach((i) => {
              this.dataForm.items.push({
                type_name: r.name,
                type_sub_name: i.name,
                value: i.value,
                ext: i.ext || '',
              })
            })
          } else {
            this.dataForm.items.push({
              type_name: r.name,
              type_sub_name: r.sub_name,
              value: r.value,
              ext: r.ext || '',
            })
          }
        })
      }

      this.dataForm.items.push({
        type_name: '',
        type_sub_name: '',
        ext: '',
        value: '',
      })
      this.loading = false
    },
  },
  async created() {
    this.loading = true
    this.dataForm.select_season = this.season_id || ''
    await this.reloadSeasons()
    this.loading = false
    this.bindSeasonData()
  },
}
</script>

<style scoped></style>
