var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"title":'编辑活动积分',"visible":_vm.integralVisible,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.integralVisible=$event},"closed":_vm.close}},[_c('el-form',{ref:"integralForm",attrs:{"label-width":"100px","model":_vm.dataForm}},[_c('el-form-item',{attrs:{"label":"门派名称","required":""}},[_c('div',[_vm._v(_vm._s(_vm.sect_name))])]),_c('el-form-item',{attrs:{"label":"期榜","required":"","prop":"select_season","rules":[
        {
          required: true,
          message: '请选择期榜',
          trigger: ['blur', 'change'],
        },
      ]}},[(_vm.from == 'rank')?_c('div',[_vm._v(_vm._s(_vm.season_name))]):_c('el-select',{ref:"select_season",model:{value:(_vm.dataForm.select_season),callback:function ($$v) {_vm.$set(_vm.dataForm, "select_season", $$v)},expression:"dataForm.select_season"}},[_c('el-option',{attrs:{"value":"","label":"请选择"}}),_vm._l((_vm.seasonList),function(item,index){return _c('el-option',{key:`season_${index}`,attrs:{"value":item._id,"label":item.name + '-' + item.year + '年'}})})],2)],1),_c('el-form-item',{attrs:{"label":"积分","required":"","prop":"items","rules":[
        {
          type: 'array',
          required: true,
          min: 2,
          message: '请添加积分',
          trigger: 'change',
        },
      ]}},[_c('div',[_vm._v(_vm._s(_vm.total_value))]),_c('el-table',{attrs:{"data":_vm.dataForm.items,"border":"","max-height":"350"}},[_c('el-table-column',{attrs:{"label":"活动类型"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label":"","prop":'items.' + scope.$index + '.type_name',"label-width":"0","rules":[
                {
                  required: scope.$index < _vm.dataForm.items.length - 1,
                  message: '请输入活动类型',
                  trigger: ['blur', 'change'],
                },
                {
                  min: scope.$index < _vm.dataForm.items.length - 1 ? 1 : 0,
                  max: 12,
                  message:
                    '长度在 ' +
                    (scope.$index < _vm.dataForm.items.length - 1 ? 1 : 0) +
                    ' 到 12 个字符',
                  trigger: ['blur', 'change'],
                },
              ]}},[_c('el-input',{attrs:{"placeholder":"活动类型","maxlength":"12"},model:{value:(scope.row.type_name),callback:function ($$v) {_vm.$set(scope.row, "type_name", $$v)},expression:"scope.row.type_name"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"活动详情"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label":"","prop":'items.' + scope.$index + '.type_sub_name',"label-width":"0","rules":[
                {
                  required: scope.$index < _vm.dataForm.items.length - 1,
                  message: '请输入活动详情',
                  trigger: ['blur', 'change'],
                },
                {
                  min: scope.$index < _vm.dataForm.items.length - 1 ? 1 : 0,
                  max: 12,
                  message:
                    '长度在 ' +
                    (scope.$index < _vm.dataForm.items.length - 1 ? 1 : 0) +
                    ' 到 12 个字符',
                  trigger: ['blur', 'change'],
                },
              ]}},[_c('el-input',{attrs:{"placeholder":"活动详情","maxlength":"12"},model:{value:(scope.row.type_sub_name),callback:function ($$v) {_vm.$set(scope.row, "type_sub_name", $$v)},expression:"scope.row.type_sub_name"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"积分"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label":"","label-width":"0","prop":'items.' + scope.$index + '.value',"rules":[
                {
                  required: scope.$index < _vm.dataForm.items.length - 1,
                  message: '请输入积分',
                  trigger: ['blur', 'change'],
                },
                { validator: _vm.validatorInteger, trigger: ['change', 'blur'] },
              ]}},[_c('el-input',{attrs:{"placeholder":"积分","type":"number"},model:{value:(scope.row.value),callback:function ($$v) {_vm.$set(scope.row, "value", $$v)},expression:"scope.row.value"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"排序"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label":"","label-width":"0","prop":'items.' + scope.$index + '.sort',"rules":[
                {
                  required: scope.$index < _vm.dataForm.items.length - 1,
                  message: '请输入排序',
                  trigger: ['blur', 'change'],
                },
                { validator: _vm.validatorInteger, trigger: ['change', 'blur'] },
              ]}},[_c('el-input',{attrs:{"placeholder":"排序","type":"number"},model:{value:(scope.row.sort),callback:function ($$v) {_vm.$set(scope.row, "sort", $$v)},expression:"scope.row.sort"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"margin-bottom":"18px"}},[(scope.$index < _vm.dataForm.items.length - 1)?_c('popconfirmButton',{staticStyle:{"margin-left":"10px"},attrs:{"content":"是否确认删除？","text":"删除"},on:{"onConfirm":function($event){return _vm.dataForm.items.splice(scope.$index, 1)}}}):_c('el-button',{staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.dataForm.items.push({
                    type_name: '',
                    type_sub_name: '',
                    ext: '',
                    value: '',
                  })}}},[_vm._v("添加")])],1)]}}])})],1),_c('div',{staticStyle:{"color":"#F56C6C","font-size":"12px","text-align":"center","margin":"5px"}},[_vm._v(" *填写内容需要点击“添加”按钮，否则不会保存 ")])],1),_c('div',{staticStyle:{"width":"100%","text-align":"center","margin-top":"10px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.saveIntegral}},[_vm._v("确定")]),_c('el-button',{staticStyle:{"margin-left":"20px"},on:{"click":_vm.close}},[_vm._v("取消")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }